<template>
    <b-container fluid="md" class="result_header mt-3">
      <b-row justify-content-md-center>
        <b-col sm class="text-nowrap p-1 ml-2 bd-highlight" >
          {{ currentCount }} &nbsp; selected of &nbsp; {{ totalCount }} results
        </b-col>
        <b-col >
          <b-button class="ml2-auto badges mt-2" variant="primary" size="sm"  v-on:click="addQueryToCollection">Save Query</b-button>
        </b-col>
      </b-row>
        <b-row align-v="center">
            <b-col sm class="text-nowrap">
                <b-input-group prepend="Sort By:" size="sm">
                    <b-form-select
                        v-model="orderBy"
                        :options="orderByOptions"
                        value-field="field"
                        text-field="title"
                        disabled-field="notEnabled"
                    ></b-form-select>
                </b-input-group>
            </b-col>

<!--
            <b-col sm>
                <b-form-checkbox v-model="searchExact" name="Match All Terms" switch>Match All Terms</b-form-checkbox>
            </b-col>
-->
            <b-col sm class="ml-auto">
                <b-input-group prepend="Result Limit:" size="sm" class="text-nowrap">
                    <b-form-select
                        v-model="limit"
                        :options="limitOptions"
                        value-field="value"
                        text-field="title"
                        disabled-field="notEnabled"
                    ></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>

        <div class="mt-3" v-if="Object.keys(filters).length > 0">
            <span align-v="center" class="filters"
                v-for="f in Object.keys(filters)"
                v-bind:key="f"
            >
                <b-badge variant="info" class="m-1"
                    v-for="applied in consolidateFilter(f)"
                    v-bind:key="applied"
                >
                    <!--{{ f }} / -->{{ applied }}
                </b-badge>
            </span>

            <b-button variant="link" size="sm" class="ml2-auto" ref="deselect" v-on:click="deselect"><b-icon icon="x"></b-icon>Clear All Filters</b-button>
        </div>
  </b-container>
</template>

<script>
//import FacetsConfig from '../../config.js'
import {mapState} from "vuex";
import {bus} from "@/main";
import localforage from "localforage";
//import {mapState} from "vuex";

export default {
  name: "ResultHeader",
  inject: ["clearFilters", "order", "setResultLimit"
 //   , "setSearchExactmatch"
  ],
  computed: {
    ...mapState(['searchExactMatch','FacetsConfig']),
  },
  props: {
    "totalCount": Number,
    "currentCount": Number,
    "sortOptions": Object,
    "filters": Object,

  },
  watch: {
    orderBy: 'orderByChanged',
    limit: 'limitChanged',
    searchExact: 'searchExactChanged'
  },
  data() {
    return {
      orderBy: "score",
      orderByOptions: [],//{field:'name', title: 'Name', sort: 'asc' },
      limit: 100,
      limitOptions:   [10,50,100,1000],
      searchExact: this.searchExactMatch,
    }
  },
  mounted() {
    this.orderBy=this.FacetsConfig.ORDER_BY_DEFAULT;
        this.orderByOptions=this.FacetsConfig.ORDER_BY_OPTIONS;//{field:'name', title: 'Name', sort: 'asc' },
        this.limit=this.FacetsConfig.LIMIT_DEFAULT;
        this.limitOptions= this.FacetsConfig.LIMIT_OPTIONS;
  }
  ,
  methods: {
    consolidateFilter: function (key){
      var filters = this.filters[key]
      if ('datep' === key) {
        var years = new Set()
        for (let i = 0; i < filters.length; i++) {
          var date = filters[i]
          years.add(date.split("-")[0])
        }
        filters = Array.from(years);
      }
      return filters
    },
    deselect() {

      this.clearFilters()
      bus.$emit('facetupdate');
    },
    orderByChanged() {
      this.order(this.orderByOptions.find(o => o.field = this.orderBy))
    },
    limitChanged() {
      this.setResultLimit(this.limit)
    },
    searchExactChanged() {
     // this.setSearchExactmatch(this.searchExact)
      this.$store.state.searchExactMatch = this.searchExact
    },
//   $('.orderbyitem').each(function(){
//   var id = this.id.substr(8);
//   if (settings.state.orderBy == id) {
//     $(this).addClass("activeorderby");
//   }
// });
// // add the click event handler to each "order by" item:
// $('.orderbyitem').click(function(event){
//   var id = this.id.substr(8);
//   settings.state.orderBy = id;
//   $(settings.facetSelector).trigger("facetedsearchorderby", id);
//   settings.state.shownResults = 0;
//   order();
//   updateResults();
// });
    addQueryToCollection() {
      var self = this
      this.clickToAddCollection = true
      // var toAdd = true
      // for(var i = 0; i < this.collections.length; i++) {
      //   var item = this.collections[i]
      //   if (item.g === this.item.g) {
      //     toAdd = false
      //     break
      //   }
      // }
      if (!this.$store.state.q || this.$store.state.q.length === 0 ) {
        return
      }
      let query = self.$store.state.q
      localforage.getItem(window.location.href, function (err, value) {
        var desp = {}
        let paramString = window.location.href.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        for(let pair of queryString.entries()) {
          if(pair[0] === 'q') continue
          if(pair[0] === 'resourceType' && pair[1] == 'all') continue
          if(!(pair[0] in desp)) {
            desp[pair[0]] = new Set()
          }
          desp[pair[0]].add(pair[1])
        }
        for (const [key, value] of Object.entries(desp)) {
          desp[key] = [...value]
        }
        if (value === null) {
          localforage.setItem(
              window.location.href,
              {'type': 'query', 'collection': 'unassigned', 'value': {'name': query, 'g': window.location.href, 'url': window.location.href , 'description': desp}}
          ).then((value) => {
            console.log("store: " + "unassigned query "+ query + value.g + " to localstorage");
          }).catch((err) => {
            console.log('oops! the account was too far gone, there was nothing we could do to save him ', err);
          });
          console.log("add to collection");
        } else {
          value['value']['description'] = desp
          value['value']['url'] = window.location.href
          value['value']['g'] = window.location.href
          localforage.setItem(
              window.location.href, value
          ).then((value) => {
            console.log("store: " + "unassigned query "+ query + value.g + " to localstorage");
          }).catch((err) => {
            console.log('oops! the account was too far gone, there was nothing we could do to save him ', err);
          });
          console.log("update to collection");
          console.log(value)
        }
      });

      // localforage.getItem(self.textQuery, function (err, value) {
      //   if (value === null) {
      //     localforage.setItem(
      //         self.textQuery,
      //         // self.item.g,
      //         self.textQuery
      //     ).then((value) => {
      //       console.log("store " + value.g + " to localstorage");
      //     }).catch((err) => {
      //       console.log('oops! the account was too far gone, there was nothing we could do to save him ', err);
      //     });
      //     console.log("add to collection");
      //   } else {
      //     // localforage.setItem(newFilename, value, function () {
      //     //   localforage.removeItem(filename, function () { return callback(); });
      //     // });
      //     console.log(value)
      //   }
      // });
      // if(toAdd) {
      //   // Vue.set(this.collections, this.collections.length, this.item)
      // }
    },
  }

}
</script>

<style scoped lang="scss">
    @import '~/src/assets/bootstrapcss/custom';

.filters {
}

</style>
